// import styles from "./home.module.scss"
// import { useNavigate } from "react-router-dom"

// // Overlay textures
// import BorderVertical from "../../assets/images/textures/vertical_trim.svg"
// import BorderHoriz from "../../assets/images/textures/horizontal_trim.svg"

// // Card backgrounds
// import RaceHub from "../../assets/images/backgrounds/race_hub.png"
// import PitCrew from "../../assets/images/backgrounds/pit_crew.png"
// import RB21 from "../../assets/images/backgrounds/rb21.png"
// import RaceSupport from "../../assets/images/backgrounds/race_support.png"


// // Main entry page for the application
// export default function Home() {
//     // Setup an instance to the router navigator
//     const navigate = useNavigate()

//     return (
//         <div className={styles.container}>
//             {/* Borders */}
//             <img src={BorderVertical} alt="Vertical Border" className={styles.vertical}/>
//             <img src={BorderHoriz} alt="Horizontal Border" className={styles.horizontal}/>

//             <div className={styles.cards}>
//                 <div
//                     className={styles.card}
//                     style={{ backgroundImage: `url("${RaceHub}")` }}
//                     onClick={() => navigate("/race-hub")}
//                 />
//                 <div
//                     className={styles.card}
//                     style={{ backgroundImage: `url("${PitCrew}")` }}
//                     onClick={() => navigate("/pit-crew")}
//                 />
//                 <div
//                     className={styles.card}
//                     style={{ backgroundImage: `url("${RaceSupport}")` }}
//                     onClick={() => navigate("/race-support")}
//                 />
//                 <div
//                     className={styles.card}
//                     style={{ backgroundImage: `url("${RB21}")` }}
//                     onClick={() => navigate("/RB21")}
//                 />
//             </div>
//         </div>
//     )
// }

import styles from "./home.module.scss"
import { useNavigate } from "react-router-dom"

// Overlay textures
import BorderVertical from "../../assets/images/textures/vertical_trim.svg"
import BorderHoriz from "../../assets/images/textures/horizontal_trim.svg"

// Card backgrounds
import RaceHub from "../../assets/images/backgrounds/race_hub.png"
import PitCrew from "../../assets/images/backgrounds/pit_crew.png"
import RB21 from "../../assets/images/backgrounds/rb21.png"
import RaceSupport from "../../assets/images/backgrounds/race_support.png"

// Main entry page for the application
export default function Home() {
    // Setup an instance to the router navigator
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            {/* Borders */}
            <img src={BorderVertical} alt="Vertical Border" className={styles.vertical}/>
            <img src={BorderHoriz} alt="Horizontal Border" className={styles.horizontal}/>

            <div className={styles.cards}>
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${RaceHub}")` }}
                    onClick={() => navigate("/race-hub")}
                />
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${PitCrew}")` }}
                    onClick={() => navigate("/pit-crew")}
               />
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${RaceSupport}")` }}
                    onClick={() => navigate("/race-support")}
                />
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${RB21}")` }}
                    onClick={() => navigate("/rb21")}
                />
            </div>
        </div>
    )
}