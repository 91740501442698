import styles from "./ops-room.module.scss"

// Images
import OperationsRoom from "../../../../assets/images/graphics/operations_room.png"
import Background from "../../../../components/background/backgroud"

// Returns the HTML markup for the ops room slide
export default function OpsRoom() {
    return (
        <>
            <Background bg={2} />
            <div className={styles.container} >
                {/* style={{ backgroundImage: `url("${Background}")` }}> */}
                {/* <h1 style={{ backgroundImage: `url("${SprayLine}")` }}>Operations Room</h1> */}

                <img className={styles.operations} src={OperationsRoom} alt="Operations Room" />
            </div>
        </>
    )
}
