import styles from "./race-hub.module.scss"
import { useEffect, useRef, useState, useContext } from "react"
import { AppContext } from "../../utils/context"

// UI components
import Background from "../../components/background/backgroud"
// import Arrow from "../../assets/images/icons/arrow.png"
import Location from "./slides/location/location"
import Schedule from "./slides/schedule/schedule"
import Standings from "./slides/standings/standings"
import Arrow from "../../assets/images/icons/arrow.svg"

// Returns the HTML makrup for the race hub
export default function RaceHub() {
    const [isFadedOut, setIsFadedOut] = useState(false)
    const [isFadedOutSwipe, setIsFadedOutSwipe] = useState(false)
    const [gridFadedOut, setGridFadedOut] = useState(true)

    // Scroll position trackers
    const [baseScrollPosition, setBaseScrollPosition] = useState(0)
    const [scrollPosition, setScrollPosition] = useState(50)

    // Pull the active event from the context
    const { event } = useContext(AppContext)

    // Pull the event_key from the event
    const { event_key } = event || {}

    // Ref to the slider
    const sliderRef = useRef(null)

    // Handle the scroll event
    const handleScroll = () => {
        // If the div ref is available
        if (sliderRef.current) {
            // Get the scroll left and screen width
            const scrollLeft = sliderRef.current.scrollLeft
            const screenWidth = window.innerWidth

            // Set the base scroll position into the state for the background
            setBaseScrollPosition(scrollLeft)

            // Work out the scroll left as a percentage of the screen width
            setScrollPosition((scrollLeft / screenWidth) * 100 * -0.25 + 50)

            // console.log(scrollLeft, screenWidth)
            setIsFadedOut(scrollLeft > screenWidth / 3)
            setIsFadedOutSwipe(scrollLeft > screenWidth / 6)
            setGridFadedOut(scrollLeft < screenWidth - 100)
        }
    }

    // On component mount
    useEffect(() => {
        // If the div ref is available
        if (sliderRef.current) {
            // Add event listener
            sliderRef.current.addEventListener("scroll", handleScroll)
        }

        // Cleanup event listener
        return () => {
            if (sliderRef.current) {
                sliderRef.current.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return (
        <Background scroll={baseScrollPosition}>
            {/* <p
                className={[styles.round, isFadedOut ? styles.fadeOut : ""].join(" ").trim()}
                style={{ left: `${scrollPosition}%` }}
            >
                {event_key?.replace("R", "")}
            </p> */}

            <div className={styles.slider} ref={sliderRef}>
                <div className={styles.slide}>
                    <Location />
                </div>
                <div className={styles.slide}>
                    <Schedule />
                </div>
                {/* <div className={styles.slide}>
                    <Standings />
                </div> */}
            </div>

            <div className={[styles.swipe, isFadedOutSwipe ? styles.fadeOut : ""].join(" ").trim()}>
                {/* <p>Schedule</p> */}
                <img src={Arrow} alt="Left Arrow" />
            </div>
        </Background>
    )
}
