"use client"

import styles from "./schedule.module.scss"
import { AppContext } from "../../../../utils/context"
import { useContext } from "react"
import moment from "moment-timezone"

// Images
import RaceBorder from "../../../../assets/images/textures/race_border.svg"

// Returns the HTML markup for the schedule slide
export default function Schedule() {
    // Pull the active event from the context
    const { event } = useContext(AppContext)

    // Return early if there's no event
    if (!event) return null

    // Pull the event sessions from the event
    const { event_sessions } = event

    // Setup an object for storing the sessions, grouped by date
    const sessionsbyDate = {}

    // Map over the event sessions and group them by date
    event_sessions.forEach((session) => {
        // Parse the date from the session
        const date = moment(session.session_times.utc.start_time).format("YYYY-MM-DD")

        // Add the session to the date group
        if (sessionsbyDate[date]) {
            sessionsbyDate[date].push(session)
        } else {
            sessionsbyDate[date] = [session]
        }
    })

    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return "th"
        switch (day % 10) {
          case 1: return "st"
          case 2: return "nd"
          case 3: return "rd"
          default: return "th"
        }
    }

    return (
        <>
            <div className={styles.container}>
                <h1
                //  style={{ backgroundImage: `url("${SprayLine}")` }}>
                >
                    <div className={styles.title}>Weekend</div>Schedule
                     {/* <span>[Local Time]</span> */}
                </h1>

                <div className={styles.grid} 
                // style={{ backgroundImage: `url("${Grid}")` }}
                >
                    {Object.entries(sessionsbyDate).map(([date, sessions]) => (
                        <div key={date} className={styles.row}>
                            
                            {/* <div className={styles.space}></div> */}
                            {sessions.map((session) => (
                                <div
                                    key={session.session_name}
                                    className={[
                                        styles.session,
                                        session.session_abbreviation === "GP" ? styles.isRace : "",
                                        session.session_times.track.end_time < moment.now() ? styles.isPast : "",
                                    ].join(" ")}
                                    style={{ backgroundImage: session.session_abbreviation === 'GP' && `url("${RaceBorder}")` }}
                                >
                                    <div className={styles.date}>
                                        <span className={styles.dateDay}>
                                        {moment(date, "YYYY-MM-DD").format("DD")}
                                            <span className={styles.dateSuffix}>
                                                {getOrdinalSuffix(moment(date, "YYYY-MM-DD").date())}
                                            </span>
                                        </span>
                                        <span className={styles.dateMonth}>{moment(date, "YYYY-MM-DD").format("MMM")}</span>
                                    </div>
                                    <div>
                                        <span>{session.session_name.replace('Free ', '').replace('Grand Prix', 'Race')}</span>
                                        <span className={styles.time}>
                                            {moment
                                                .tz(
                                                    session.session_times.track.start_time,
                                                    session.session_times.track.timezone
                                                )
                                                .format("HH:mm")}{" "}
                                            -{" "}
                                            {moment
                                                .tz(
                                                    session.session_times.track.end_time,
                                                    session.session_times.track.timezone
                                                )
                                                .format("HH:mm")}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
