import styles from "../rb21.module.scss"
import { useContext } from "react"
import { AppContext } from "../../../utils/context"
import { printTranslation } from "../../../utils/translations"

// Images
// import Open from "../../../assets/images/icons/open.png"
import Open from "../../../assets/images/icons/touchpoint_close.png"
import Close from "../../../assets/images/icons/close.png"
import Background from "../../../assets/images/textures/touchpoint_bg.png"
import Arrow01 from "../../../assets/images/icons/arrow_01.svg"
import Arrow02 from "../../../assets/images/icons/arrow_02.svg"
import Arrow03 from "../../../assets/images/icons/arrow_03.svg"
import Arrow04 from "../../../assets/images/icons/arrow_04.svg"
import Arrow05 from "../../../assets/images/icons/arrow_05.svg"
import Arrow06 from "../../../assets/images/icons/arrow_06.svg"
import Arrow07 from "../../../assets/images/icons/arrow_07.svg"

// Returns the HTML markup for the toucpoint
export default function Touchpoint({ id, translationKey, active, setActive, title, content }) {
    // Check to see if the touchpoint is active
    const isActive = id === active

    // Get the language from the context
    const { lang } = useContext(AppContext)

    // Get the translations for the content
    const translatedTitle = printTranslation(`${translationKey}_Title`, lang, title)
    const translatedContent = printTranslation(`${translationKey}_Content`, lang, content)

    return (
        <div
            className={[
                styles.touchpoint,
                styles[id],
                active && active !== id ? styles.hide : "",
            ].join(" ")}
            onClick={() => setActive(id)}
        >
            {id === "frontWing" && <img className={styles.arrow} src={Arrow06} alt="Arrow" />}
            {id === "frontSuspension" && <img className={styles.arrow} src={Arrow04} alt="Arrow" />}
            {id === "halo" && <img className={styles.arrow} src={Arrow01} alt="Arrow" />}
            {id === "floor" && <img className={styles.arrow} src={Arrow02} alt="Arrow" />}
            {id === "coolingSidePods" && <img className={styles.arrow} src={Arrow03} alt="Arrow" />}
            {id === "rearSuspension" && <img className={styles.arrow} src={Arrow07} alt="Arrow" />}
            {id === "rearWing" && <img className={styles.arrow} src={Arrow05} alt="Arrow" />}

            <p className={styles.title}>
                {translatedTitle}
                <img className={styles.open} src={Open} alt="Open" />
            </p>

            <div className={[styles.content, isActive ? styles.isShowing : ""].join(" ").trim()} style={{ backgroundImage: `url("${Background}")` }}
            >
                <img className={styles.closeTouchpoint} src={Close} alt="Close" />
                {/* <img className={styles.border} src={Border} alt="Lower Border" /> */}

                <p>{translatedTitle}</p>
                <p>{translatedContent}</p>
            </div>
        </div>
    )
}
