import styles from "./location.module.scss"
import { AppContext } from "../../../../utils/context"
import { useContext } from "react"
import { printTranslation } from "../../../../utils/translations"

// Import the track maps
import { Tracks } from "./tracks"

import TrackInfo from "../../../../assets/images/textures/track_info.svg"

import StatsBG from "../../../../assets/images/textures/track_stats_bg.png"

// Returns the HTML markup for the location slide
export default function Location() {
    // Pull the active event from the context
    const { event, lang } = useContext(AppContext)

    // Returns early if the event is not available
    if (!event) return null

    // Pull the longitude and latitude from the event
    const {
        country_name,
        location_latitude,
        location_longitude,
        track_name,
        track_laps,
        track_lap_distance,
        track_race_distance,
        track_first_gp,
        track_record_driver,
        track_deg,
        track_downforce
    } = event

    // console.log(event)

    // Setup a function for converting decimal coordinates to DMS
    const convertDecimalToDMS = (lat, lng) => {
        // Handle negative values and determine directions
        const latDirection = lat >= 0 ? "N" : "S"
        const lngDirection = lng >= 0 ? "E" : "W"

        // Get absolute values for calculations
        const absoluteLat = Math.abs(lat)
        const absoluteLng = Math.abs(lng)

        // Extract degrees
        const degLat = Math.floor(absoluteLat)
        const degLng = Math.floor(absoluteLng)

        // Calculate minutes
        const minLat = (absoluteLat - degLat) * 60
        const minLng = (absoluteLng - degLng) * 60

        // Calculate seconds
        const secLat = (minLat - Math.floor(minLat)) * 60
        const secLng = (minLng - Math.floor(minLng)) * 60

        // Return formatted DMS string
        const p1 = `${degLat}°${Math.floor(minLat)}'${parseInt(secLat)}"${latDirection}`
        const p2 = `${degLng}°${Math.floor(minLng)}'${parseInt(secLng)}"${lngDirection}`
        return `${p1} ${p2}`
    }

    // Location coordinates
    const coordinates = convertDecimalToDMS(location_latitude, location_longitude)

    return (
        <>
            <div className={styles.container}>
                <h1>{printTranslation(`${event?.event_key}_CountryName`, lang, country_name)}</h1>
                <h3>{printTranslation(`${event?.event_key}_TrackName`, lang, track_name)}</h3>

                <div className={styles.row}>
                    <div className={styles.track}>
                        <img src={Tracks[event?.track_name]} alt={track_name} />
                    </div>

                    <div 
                        className={styles.stats}
                        style={{ backgroundImage: `url(${StatsBG})` }}
                    >
                        <div className={styles.stats}>
                            <div className={styles.stat}>
                                <p >FIRST GRAND PRIX</p>
                                <span>{track_first_gp}</span>
                            </div>

                            <div className={styles.stat}>
                                <p>LAPS</p>
                                <span>{track_laps}</span>
                            </div>

                            <div className={styles.stat}>
                                <p>CIRCUIT LENGTH</p>
                                <span>{track_lap_distance}</span>
                            </div>

                            <div className={styles.stat}>
                                <p>RACE DISTANCE</p>
                                <span>{track_race_distance}</span>
                            </div>

                             <div className={styles.stat}>
                                <p>LAP RECORD HOLDER</p>
                                <span>{track_record_driver}</span>
                            </div>
                        </div>

                        <div className={styles.trackInfo}>
                            <img src={TrackInfo} alt="Track Information" />
                            <div className={styles.trackInfoContent}>
                                <p className={styles.downforce}>{track_downforce}<br/> Downforce Circuit</p>
                                <p className={styles.deg}>{track_deg}<br/> Degredation Circuit</p>
                            </div>
                        </div>                       
                    </div>
                
                </div>
            </div> 
        </>
    )
}
