import AdamDodds from "./adam_dodds.jpg"
// import AdamWootton from "./adam_wootton.jpg"
// import AndyRush from "./andy_rush.jpg"
// import CalumNicholas from "./calum_nicholas.jpg"
// import CallumAdams from "./callum_adams.jpg"
import ChrisGent from "./chris_gent.jpg"
import EdHemsworth from "./ed_hemsworth.jpg"
import JackHarrison from "./jack_harrison.jpg"
import JonAppleby from "./jon_appleby.jpg"
import JonCaller from "./jon_caller.jpg"
import LukeHeath from "./luke_heath.jpg"
import LukePlatkiw from "./luke_platkiw.jpg"
// import MarioLabestos from "./mario_labestos.jpg"
// import MarkHerrick from "./mark_herrick.jpg"
// import MarkWillis from "./mark_willis.jpg"
import MattCaller from "./matt_caller.jpg"
// import MichaelaghTennyson from "./michaelagh_tennyson.jpg"
// import NathanLewis from "./nathan_lewis.jpg"
// import NickEdwards from "./nick_edwards.jpg"
import OllieHarcombe from "./ollie_harcombe.jpg"
// import PhilTurner from "./phil_turner.jpg"
// import RichTomlinson from "./rich_tomlinson.jpg"
// import RichWolverson from "./rich_wolverson.jpg"
import SamVincent from "./sam_vincent.jpg"
import TaylorSmith from "./taylor_smith.jpg"
import BenWinch from "./ben_winch.jpg"
import JamesHarvey from "./james_harvey.jpg"
import OleSchack from "./ole_schack.jpg"
import OscarHemsworth from "./oscar_hemsworth.jpg"
import SebFrancois from "./seb_francois.jpg"
import BenMiddlicott from "./ben_middlicott.png"
import MichaelaghTennyson from "./michaelagh_tennyson.png"
import OliverMcguireHodge from "./oliver_mcguire-hodge.png"

export const pictures = {
    AdamDodds,
    // AdamWootton,
    // AndyRush,
    // CalumNicholas,
    // CallumAdams,
    ChrisGent,
    EdHemsworth,
    JackHarrison,
    JonAppleby,
    JonCaller,
    LukeHeath,
    LukePlatkiw,
    // MarioLabestos,
    // MarkHerrick,
    // MarkWillis,
    MattCaller,
    // MichaelaghTennyson,
    // NathanLewis,
    // NickEdwards,
    OllieHarcombe,
    // PhilTurner,
    // RichTomlinson,
    // RichWolverson,
    SamVincent,
    TaylorSmith,
    BenWinch,
    JamesHarvey,
    OleSchack,
    OscarHemsworth,
    SebFrancois,
    BenMiddlicott,
    MichaelaghTennyson,
    OliverMcguireHodge
}
