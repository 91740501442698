import ChristianHorner from "./christian_horner.png"
import GianPieroLambiase from "./gianpiero_lambiase.png"
import PierreWache from "./pierre_wache.png"
import RichardWolverson from "./richard_wolverson.png"
import HannahSchmitz from "./hannah_schmitz.png"

export const pictures = {
    ChristianHorner,
    GianPieroLambiase,
    PierreWache,
    RichardWolverson,
    HannahSchmitz
}
