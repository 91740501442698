import styles from "./pit-crew.module.scss"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

// Bios
import bios from "./assets/bios.json"
import { pictures } from "./assets/pictures/pictures"

// Images
import PitCrewImage from "../../assets/images/graphics/pit_crew.png"
import ModalBackground from "../../assets/images/textures/modal_background.png"
import OpenIcon from "../../assets/images/icons/touchpoint_close.png"
import Background from "../../components/background/backgroud"

// Returns the HTML markup for the pit crew slide
export default function PitCrew() {
    const [modalToggled, setModalToggled] = useState(false)
    const [activeProfile, setActiveProfile] = useState(null)

    // Setup a reference to the navigation
    const navigate = useNavigate()

    // When the modalToggled state is changed
    useEffect(() => {
        if (!modalToggled) {
            setTimeout(() => {
                setActiveProfile(null)
            }, 350)
        }
    }, [modalToggled])

    return (
        <>
            <Background bg={2}/>
            <div
                 className={styles.background} 
                //  style={{ backgroundImage: `url(${PitCrewImage})` }}
                 >

                <div  className={styles.pitCrew}>
                    <img
                         src={PitCrewImage}
                    />
                </div>
                

                <div className={styles.personToggles}>
                    {bios.map((bio, index) => (
                        <div
                            key={index}
                            className={styles.toggle}
                            style={{
                                backgroundImage: `url(${OpenIcon})`,
                                left: `${bio.position.left}vw`,
                                top: `${bio.position.top}vh`,
                            }}
                            onClick={() => {
                                setModalToggled(true)
                                setActiveProfile(bio)
                            }}
                        />
                    ))}
                </div>
            </div>

            <div
                className={[styles.modal, modalToggled ? styles.isToggled : ""].join(" ")}
                onClick={() => setModalToggled(false)}
            >
                <div className={styles.card}>
                    <img src={ModalBackground} className={styles.graphic} />
                    {/* <img src={Sticker} className={styles.sticker} /> */}

                    <div className={styles.grid}>
                        <div
                            className={styles.imageContainer}
                            // style={{ backgroundImage: `url(${SprayLines})` }}
                        >
                            
                                <div
                                    className={styles.profilePicture}
                                    style={{
                                        backgroundImage: `url(${pictures[activeProfile?.image]})`,
                                    }}
                                />
                            
                        </div>

                        <div className={styles.textContainer}>
                            <div
                                className={styles.name}
                                // style={{ backgroundImage: `url(${NameCircle})` }}
                            >
                                <p>{activeProfile?.name}</p>
                            </div>

                            <div className={styles.bio}>
                                <p>
                                    Position In Pit
                                    <br />
                                    <span>{activeProfile?.pit_position}</span>
                                </p>

                                {activeProfile?.fav_circuit && (
                                    <p>
                                        Favourite Circuit:
                                        <br />
                                        <span>{activeProfile?.fav_circuit}</span>
                                    </p>
                                )}

                                {activeProfile?.fav_bull && (
                                    <p>
                                        Favourite Red Bull:
                                        <br />
                                        <span>{activeProfile?.fav_bull}</span>
                                    </p>
                                )}

                                {activeProfile?.fav_moment && (
                                    <p>
                                        Favourite Moment:
                                        <br />
                                        <span>{activeProfile?.fav_moment}</span>
                                    </p>
                                )}

                                {activeProfile?.fav_tune && (
                                    <p>
                                        Favourite Tune:
                                        <br />
                                        <span>{activeProfile?.fav_tune}</span>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
